<template>
  <div>
    <md-table  >
      <md-table-toolbar>
        <h1 class="md-title">Partida</h1>
      </md-table-toolbar>
      <md-table-row>
        <md-table-head>Descripción</md-table-head>
        <md-table-head>Cuenta</md-table-head>
        <md-table-head width="30px" md-numeric>Debe</md-table-head>
        <md-table-head width="30px" md-numeric>Haber</md-table-head>
        <md-table-head width="40px"></md-table-head>
      </md-table-row>
      <md-table-row  v-for="(item, index) in list_data.mdData" :key="item.id"
      :class="{ 'null error-text': !item.status}">
        <md-table-cell v-if="item.edit && item.status"
          md-label="Descripción" class="customWidthClass2">
          <md-autocomplete autocomplete="off"
            id="account_name" class="autocomplete"
            :disabled="sending"
            :title="!item.account_bank_name ? item.account_name :
            item.account_name + ' ('+ item.account_bank_name+' )'"
            v-model="item.account_name"
            md-input-name="account"
            :md-options="list_accounts"
            @md-selected="set_account"
            ref="input_autocomplete"
            @md-changed="get_list_accounts" >
          <template
            slot="md-autocomplete-item" slot-scope="{ item, term }">
            <span v-if="item.account_bank" :title="item.name +' ('+ item.name_account_bank+' )'">
              {{ item.nature }} {{ item.name }} ( {{item.name_account_bank}} )</span>
              <span :title="item.name">
                <md-highlight-text :md-term="term" v-if="!item.account_bank">
                {{ item.nature }} {{ item.name }}</md-highlight-text>
              </span>
          </template>
          </md-autocomplete>
        </md-table-cell>
        <md-table-cell v-else md-label="Descripción" >
          <span>{{item.account_name }}</span>
          <span v-if="item.account_bank_name"> ( {{item.account_bank_name }} )</span>
        </md-table-cell>

        <md-table-cell v-if="item.edit && item.status"
          md-label="Cuenta" class="customWidthClass3">
          <md-field>
          <md-input name="cuenta" class="input"
            id="cuenta" readonly
            v-model="item.nomenclature" :disabled="sending" />
        </md-field>

        </md-table-cell>
        <md-table-cell v-else md-label="Cuenta" >
          {{ item.nature }} -
          {{ item.num_cuenta }}
        </md-table-cell>

        <md-table-cell v-if="item.edit && item.status"
          md-label="Debe" class="customWidthClass4">
          <md-field>
          <md-input name="debe" type="number" class="input"
            id="debe"
            ref="input_debe"
            v-model="item.debe" :disabled="sending" />
          </md-field>
        </md-table-cell>
          <md-table-cell v-else md-label="Debe" md-numeric>
            {{ item.debe|formatPrice }}
        </md-table-cell>

        <md-table-cell v-if="item.edit && item.status"
          md-label="Haber" class="customWidthClass4">
          <md-field>
            <md-input name="haber" type="number" class="input"
              id="haber"
              ref="input_haber"
              v-model="item.haber" :disabled="sending" />
          </md-field>
          </md-table-cell>
          <md-table-cell v-else md-label="Haber" md-numeric>
            {{ item.haber|formatPrice }}
          </md-table-cell>

        <md-table-cell v-if="view">
          <md-button v-if="!item.edit" class="md-icon-button"
            @click="change_to_edit_detail(item, index)">
            <md-icon class="md-size-1x">edit</md-icon>
            </md-button>
            <md-button v-if="item.edit" class="md-icon-button"
              @click="confirmRow(item)">
              <md-icon class="md-size-1x">check</md-icon>
            </md-button>
            <md-button v-if="item.edit" class="md-icon-button"
              @click="delete_row(item, index)">
              <md-icon class="md-size-1x">delete</md-icon>
            </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-cell>
        </md-table-cell>
        <md-table-cell>
        </md-table-cell>
        <md-table-cell>
          {{ this.sumDebe|formatPrice }}
        </md-table-cell>
        <md-table-cell>
          {{ this.sumHaber|formatPrice }}
        </md-table-cell>
        <md-table-cell>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <h2 class="moveRigth" :class=" { 'error': dif != 0}">
      Diferencia: {{ this.dif }}</h2><br>
      <span class="md-error">
      Partida tiene que estar cuadrada para guardar</span>
  </div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2'
export default {
  name: 'BasePartida',
  props: {
    id_partida: String,
    default: null,
    view: Boolean,
    default: true
  },
  created() {
    this.$nextTick(() => {
      if (this.id_partida){
        this.get_partida(this.id_partida);
      }
    });
  },
  data() {
    return {
      list_accounts: [],
      base: process.env.VUE_APP_BASE_URL,
      instance: {},
      list_data: {
        mdData: []
      },
      url_accounts: 'accounting_account/',
      url_partida: 'partida_detail/',
      totalDebe: 0.00,
      totalHaber: 0.00,
      diferencia: 0.00,
      edit: false,
      sending: false,
      row_empty: {
        id: "",
        edit: false,
        account: "",
        account_name: "Sin cuenta contable",
        nomenclature: "",
        debe: 0.00,
        haber: 0.00,
        status: true
      },
    };
  },
  methods: {
    handlerClearInput(name, index) {
      this.$nextTick( () =>{
        this.$refs[name][index].$el.querySelector('input').focus();
      })
    },
    change_to_edit_detail(item, index) {
      item.edit = !item.edit;
      item.status = 1;
      this.$set(this.list_data.mdData, item, index);
      if (!item.account){
        item.account_name = '';
        this.handlerClearInput('input_autocomplete',index)
      }
    },
    set_account(obj) {
      this.list_data.mdData.map(dato =>{
        if(dato.account_name && dato.status===1){
          dato.account_name = obj.name;
          if (obj.name_account_bank) {
            dato.account_name = obj.name +' ('+ obj.name_account_bank+' )';
          }
          dato.nomenclature = obj.nomenclature;
          dato.account = obj.id;
        }
      });
    },
    get_list_accounts(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
        payload.company = localStorage.getItem('office')
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_accounts).then((data) => {
        self.list_accounts = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    new_row(){
      let row = {
        id: "",
        edit: false,
        account: "",
        account_name:  "Agregar cuenta contable",
        nomenclature: "",
        debe: parseFloat(0),
        haber: parseFloat(0),
        status: true,
      }
      this.list_data.mdData.unshift(row)
    },
    delete_row(item, index){
      if (item.id){
        const self = this
          Swal.fire({
            title: "¿Esta seguro?",
            text: "El registro será borrado y no se podrá recuperar.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            showLoaderOnConfirm: true,
            preConfirm: function (email) {
                const payload = item
               return self.deleteRequestDetallePartida(payload).then(function (data){
                  Swal.fire(
                    'Borrado!',
                    'El registro ha sido borrado.',
                    'success'
                  )
                  self.sending = false
                  self.list_data = {
                    mdData: data.data
                  }
                  self.new_row()
                }).catch(function (error) {
                  self.sending = false
                  if (typeof (error.response.data) === 'object') {
                    const result = error.response.data
                    Object.entries(result).forEach(
                      ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
                    )
                    return false
                  }
                  self.$swal.fire('Error!', error.response.data, 'error')
                })
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }else {
              item.edit = false
              Swal.fire(
                'Cancelado',
                'El registro esta seguro.',
                'error'
              )

            }
        });
      }else{
        item.nomenclature = ''
        item.account_name = ''
        item.haber = parseFloat(0)
        item.debe = parseFloat(0)
        item.edit = false
        this.$set(this.list_data.mdData, item, index);
      }
    },
    confirmRow(item) {
      if (!item.account_name) {
        this.$swal.fire('Error!', 'La cuenta es requerida.', 'error')
        return false
      }
      if (parseFloat(item.debe) === 0.00 && parseFloat(item.haber) === 0.00) {
        this.$swal.fire('Error!', 'Debe de ingresar un valor en debe o haber.', 'error')
        return false
      }
      if (parseFloat(item.debe) >= 0.01 && parseFloat(item.haber) >= 0.01) {
        this.$swal.fire('Error!', 'No puede ingresar valores en Debe y Haber.', 'error')
        return false
      }
      const payload = item
      if (!payload.haber) {
        payload.haber = 0
      }
      if (!payload.debe){
        payload.debe = 0
      }
      payload.book = this.id_partida
      const self = this
      this.sending = true
      if (item.id) {
        this.putRequestDetallePartida(payload).then(function (data) {
          self.sending = false
          self.list_data = {
            mdData: data.data
          }
          self.new_row()
        }).catch(function (error) {
          console.log(error)
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      this.postRequestDetallePartida(payload, this.url_partida).then(function (data) {
        self.sending = false
        self.list_data = {
          mdData: data.data
        }
        self.new_row()
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    get_partida(id) {
      const self = this
      const payload = {
        book: id,
      }
      this.sendRequest(payload, this.url_partida).then((data) => {
        self.list_data = {
          mdData: data.data
        }
        self.new_row()
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequestDetallePartida (payload) {
      const result = await this.$http.post(this.url_partida, payload)
      return result
    },
    async putRequestDetallePartida (payload) {
      const result = await this.$http.put(`${this.url_partida}${payload.id}/`, payload)
      return result
    },
    async deleteRequestDetallePartida (payload) {
      const result = await this.$http.delete(`${this.url_partida}${payload.id}/`, payload)
      return result
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    format_number: function (value) {
      if (!value) return ''
        return isNaN(value) ? 0 : parseFloat(value).toFixed(2)
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(5).replace('.', '.');
      return val.toString().replace(/\B(?=(\d{6})+(?!\d))/g, ',');
    },
  },
  computed: {
    sumDebe: function() {
      let totalDebe = this.list_data.mdData.filter( item => item.status).filter( item => item.debe).reduce((total, valor) => {
        return total + parseFloat(valor.debe);
      },0);
      return parseFloat(totalDebe).toFixed(10);
    },
    sumHaber: function() {
      let totalHaber = this.list_data.mdData.filter( item => item.status).filter( item => item.haber).reduce((total, valor) => {
        return total + parseFloat(valor.haber);
      },0);
      return parseFloat(totalHaber).toFixed(10);
    },
    dif: function() {
      return parseFloat((this.sumDebe)-parseFloat(this.sumHaber)).toFixed(10);;
    }
  },
};
</script>

<style lang="css" scoped>
  .moveRigth{
    display: block;
    float: right;
    margin-right: 20%;
    margin-top: 2%;
  }
  table {
    font-size: 2em;
  }
  .customWidthClass{
    width: 10%;
  }
  .customWidthClass2{
    width: 40%;
  }
  .customWidthClass3{
    width: 15%;
  }
  .customWidthClass4{
    width: 15%;
  }
  .input[type=text]{
    width: 10px;
  }
  .input[type=number]{
    width: 15px;
  }
  .autocomplete{
    width: 350px;
  }
  .error-text {
    color: #e32525;
  }
  .null{
    text-decoration: line-through;
  }
</style>
